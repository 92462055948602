import React, { useEffect } from "react";
import logo from "./logo.svg";
import outline from "./assets/outline.svg";
import api from "./assets/api.svg";
import computer from "./assets/computer.svg";
import mobileNote from "./assets/Mobile note list-pana.svg";
import browsing from "./assets/Browsing online-pana.svg";
import globe from "./assets/globe.svg";
import arrowup from "./assets/arrow-up.svg";
import mobileApps from "./assets/Mobile apps-cuate.svg";
import "./App.css";
import { analytics } from ".";
import { useTranslation } from "react-i18next";
import "./i18n";

const TalkToSpecialist = () => {
  const { t } = useTranslation();

  return (
    <a
      href="https://api.whatsapp.com/send?phone=5511949993319&text=Olá,+gostaria+de+conhecer+melhor+os+seus+serviços."
      className="specialist"
      target="_blank"
      rel="noreferrer"
      onClick={() => analytics.logEvent("clicked_to_talk")}
    >
      {t("talkToSpecialist")}
    </a>
  );
};

const horizontalCardsProps = [
  {
    backgroundColor: "#01cfff",
    imgProp: outline,
    title: "mobileApps",
    description: "blueDescription",
  },
  {
    backgroundColor: "#FF4C4E",
    imgProp: api,
    title: "apiWeb",
    description: "redDescription",
  },
  {
    backgroundColor: "#FFDF00",
    imgProp: computer,
    title: "websites",
    description: "yellowDescription",
  },
];

function App() {
  const { t } = useTranslation();

  useEffect(() => {
    (() => {
      analytics.logEvent("page_view", { page_title: "home" });
    })();
  }, []);

  return (
    <div className="App">
      <img src={logo} className="App-logo" alt="logo" />
      <div>
        <div className="grid">
          <div className="A grid-item">
            <h1>{t("solutionAndInnovation")}</h1>
            <p>{t("objectives")}</p>
            {TalkToSpecialist()}
          </div>
          <div className="G grid-item">
            <img src={mobileApps} alt="" className="imgs" />
          </div>
          <div className="B grid-item">
            <h3>{t("knowBetter")}</h3>
            <div className="horizontal-scroll">
              {horizontalCardsProps.map(
                ({ backgroundColor, description, imgProp, title }) => (
                  <div className="card" style={{ backgroundColor }}>
                    <div>
                      <img
                        src={imgProp}
                        className="App-logo"
                        alt="logo"
                        style={{ width: 45, height: 42 }}
                      />
                    </div>
                    <h4>{t(title)}</h4>
                    <p>{t(description)}</p>
                  </div>
                )
              )}
            </div>
          </div>
          <div className="C grid-item">
            <h2>{t("whyHave")}</h2>
            <p>{t("appDescription")}</p>
          </div>
          <div className="D grid-item">
            <img src={mobileNote} alt="" className="imgs" />
          </div>
          <div className="E grid-item">
            <h2>{t("morePotential")}</h2>
            <p>{t("websiteDescription")}</p>
            {TalkToSpecialist()}
          </div>
          <div className="F grid-item">
            <img src={browsing} alt="" className="imgs" />
          </div>
        </div>
        <footer>
          <div>
            <img src={globe} alt="" />
            <h4>{t("contactUs")}</h4>
            <p>{t("fastAnswer")}</p>
            {TalkToSpecialist()}
          </div>
          <div className="fixed">
            <img src={logo} alt="logo" className="fixed-logo" />
            <div
              className="fixed-arrow"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              <img src={arrowup} className="fixed-img" alt="logo" />
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
